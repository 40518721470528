/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect } from "react";
import { window } from 'browser-monads';
import { withPreview } from 'gatsby-source-prismic';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  Highlight,
  Snippet,
  usePagination,
  Configure,
  useStats,
} from 'react-instantsearch';
import { Inner } from 'styles/structure';
import styled from '@emotion/styled';
import colors from "styles/colors";
import dimensions from "styles/dimensions";

const SearchResults = styled.div`
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
`;

const StatsText = styled.h3`
  color: ${colors.grey600};
  font-size: 3.5rem;
  margin-bottom: 4rem;
  
  q {
    color: ${colors.grey800};
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 5.6rem;
  }
`

const SearchHit = styled.a`
  display: block;
  width: 100%;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid ${colors.grey300};
  margin-bottom: 3.2rem;
  
  span {
    color: inherit;  
  }
  
  header {
    margin-bottom: 8px;
    color: ${colors.teal700};
    font-size: 1.6rem;
    font-weight: 600;
  }
  
  h4 {
    color: ${colors.grey800};
    font-size: 2.2rem;
    line-height: 1.17;
    max-width: 84.8rem;
    margin-bottom: 0.8rem;
  }
  
  p {
    color: ${colors.grey600};
    font-size: 1.8rem;
    line-height: 1.55;
    max-width: 84.8rem;
    
    mark {
      font-weight: 500;
    }
  }

  mark {
    background: none;
    color: ${colors.teal700};
  }
  
  &:hover {
    h4,
    h4 mark {
      color: ${colors.yellow500} !important;
      transition: color 0.08s ease-in-out;
    }
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    h4 {
      font-size: 3.5rem;
    }
  }
`;

const PaginationList = styled.ul`
  margin-top: 5.6rem;

  li {
    display: inline-block;
    
    strong {
      pointer-events: none;
      color: ${colors.orange500};
      font-weight: 500;
    }
    
    span {
      opacity: 0.5;
      pointer-events: none;
    }

    &--firstPage {
      .ais-Pagination-link {
        padding-left: 0;
      }
    }

    &--lastPage {
      .ais-Pagination-link {
        padding-right: 0;
      }
    }
  }
  
  a, span {
    color: ${colors.grey700};
    font-size: 1.6rem;
    padding: 0.4rem 1rem;
  }
  
  a {
    transition: color 0.08s ease-in-out;

    &:hover {
      color: ${colors.orange500};
    }
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    a, span {
      font-size: 1.8rem;
      padding: 0.8rem 1.2rem;
    }
  }
`

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Hit = ({ hit }) => {
  let type;

  switch(hit.type) {
    case 'resource':
      type = "Resource • "
      break;
    case 'program':
      type = "Program • "
      break;
    case 'program_arm':
      type = "Program Area"
      break;
    case 'post':
      type = "Post • "
      break;
    case 'person':
      type = "Team Member"
      break;
    case 'author':
      type = "Author"
      break;
    default:
      type = null
  }

  return (
    <SearchHit href={hit.url}>
      {(hit.category || type) && <header>{type && type}{hit.category && hit.category}</header>}

      <h4><Highlight attribute="title" hit={hit} /></h4>
      <p><Snippet hit={hit} attribute="body" /></p>
    </SearchHit>
  );
}

const Stats = () => {
  const { nbHits, query } = useStats();

  return (
    <StatsText>
      {nbHits.toLocaleString()} results for <q>"{query}"</q>
    </StatsText>
  );
}

const CustomPagination = (props) => {
  const {
    pages,
    currentRefinement,
    nbPages,
    isFirstPage,
    isLastPage,
    canRefine,
    refine,
    createURL,
  } = usePagination(props);

  if (!canRefine) {
    return null;
  }

  const firstPageIndex = 0;
  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;
  const lastPageIndex = nbPages - 1;

  return (
    <PaginationList>
      <PaginationItem
        isDisabled={isFirstPage}
        href={createURL(firstPageIndex)}
        onClick={() => refine(firstPageIndex)}
      >
        ‹‹
      </PaginationItem>

      <PaginationItem
        isDisabled={isFirstPage}
        href={createURL(previousPageIndex)}
        onClick={() => refine(previousPageIndex)}
      >
        ‹
      </PaginationItem>

      {pages.map((page) => {
        const label = page + 1;

        return (
          <PaginationItem
            key={page}
            isDisabled={false}
            aria-label={`Page ${label}`}
            href={createURL(page)}
            onClick={() => {
              refine(page);

              window.scrollTo(0,0);
            }}
          >
            {page === currentRefinement ? <strong>{label}</strong> : label}
          </PaginationItem>
        );
      })}

      <PaginationItem
        isDisabled={isLastPage}
        href={createURL(nextPageIndex)}
        onClick={() => refine(nextPageIndex)}
      >
        ›
      </PaginationItem>

      <PaginationItem
        isDisabled={isLastPage}
        href={createURL(lastPageIndex)}
        onClick={() => refine(lastPageIndex)}
      >
        ››
      </PaginationItem>
    </PaginationList>
  );
}

const PaginationItem = ({ isDisabled, href, onClick, ...props }) => {
  if (isDisabled) {
    return (
      <li>
        <span {...props} />
      </li>
    );
  }

  return (
    <li>
      <a
        href={href}
        onClick={(e) => {
          e.preventDefault();

          onClick(e);
        }}
        {...props}
      />
    </li>
  );
}

const RenderBody = ({ search }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const queryParam = urlParams.get('q');

  return (
    <>
      <Inner>
        <SearchResults>
          <InstantSearch
            indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
            searchClient={searchClient}
            insights
          >
            <Configure hitsPerPage={10} attributesToSnippet={['body:40']} query={queryParam ? queryParam : ""} />
            <Stats />
            <Hits hitComponent={Hit} />

            <CustomPagination />
          </InstantSearch>
        </SearchResults>
      </Inner>
    </>
  );
}

const Search = ({ data }) => {
  //Required check for no data being returned
  const search = data.allPrismicSearch.edges[0].node.data;

  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <Layout>
      <Seo
        title={search.social_title || 'Search Leading Edge'}
        description={search.social_description}
        image={search.social_image}
      />

      <RenderBody search={search}/>
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicSearch {
      edges {
        node {
          data {
            social_title
            social_description
            social_image {
              url
            }
          }
        }
      }
    }
  }
`;

export default withPreview(Search);